<template>
  <div v-loading="loading">
    <el-card shadow="never" :body-style="{ padding: '20px' }">
      <div slot="header">
        <span class="iconfont icon-ScrollNotice">&nbsp;滚动通知</span>
      </div>
      <div class="card_header_flex">
        <div>
          <el-button type="primary" size="small" @click="dialogAddNotice=true">新增通知</el-button>
        </div>
      </div>
      <el-table :data="list" size="mini">
        <el-table-column prop="room" label="房间号" width="200">
          <template slot-scope="scope">{{scope.row.tag=='on'?'所有房间':scope.row.room}}</template>
        </el-table-column>
        <el-table-column prop="message" label="通知内容" show-overflow-tooltip></el-table-column>
        <el-table-column prop="display" label="展示方式" width="150">
          <template slot-scope="scope">{{scope.row.display=='desk'?'桌面显示':'所有APP显示'}}</template>
        </el-table-column>
        <el-table-column label="开始时间/截止时间" width="300">
          <template slot-scope="scope">
            <span
              v-if="scope.row.effect=='only'"
            >{{scope.row.createtimeDate}}~{{scope.row.endtimeDate}}</span>
            <span v-else>{{subStr(scope.row.createtimeStr)}}~{{subStr(scope.row.endtimeStr)}}</span>
          </template>
        </el-table-column>

        <el-table-column label="有效期" width="200">
          <template slot-scope="scope">
            <span>{{scope.row.effect=='only'?scope.row.isExpire:'每天'}}</span>
          </template>
        </el-table-column>

        <el-table-column label="是否启用" width="100">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.state"
              active-value="on"
              inactive-value="off"
              @change="onChangeSubmitState(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="openEditNotice(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="delNotice(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加通知 -->
    <el-dialog
      :visible.sync="dialogAddNotice"
      :close-on-click-modal="false"
      width="40%"
      @closed="closeDialogAddNotice"
    >
      <span slot="title">新增通知</span>
      <el-form
        :model="form"
        ref="form"
        label-width="80px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item label="通知类型:">滚动通知</el-form-item>
        <el-form-item
          label="通知内容"
          prop="message"
          :rules="{ required: true, message: '请输入通知内容', trigger: 'blur' }"
        >
          <el-input v-model="form.message" type="textarea" :rows="5"></el-input>
        </el-form-item>
        <el-form-item label="房间号">
          <el-checkbox
            v-model="form.tag"
            @change="onChangecheckAll"
            true-label="on"
            false-label="off"
          >全部</el-checkbox>
          <div class="down-tree">
            <el-tag
              v-for="item in roomList"
              :key="item.clientId"
              @click="item.isSelected=!item.isSelected"
              :effect="item.isSelected?'dark':'light'"
              style="margin:5px 10px;padding:0 30px;cursor: pointer;  -webkit-user-select: none;-moz-user-select: none;-o-user-select: none;user-select: none;"
            >{{item.room}}</el-tag>
          </div>
        </el-form-item>
        <el-form-item label="生效方式">
          <el-radio v-model="form.effect" label="only">一次性</el-radio>
          <el-radio v-model="form.effect" label="every">每天</el-radio>
        </el-form-item>
        <el-row :gutter="20" v-if="form.effect=='only'">
          <el-col :span="12">
            <el-form-item
              label="生效时间"
              prop="createtimeDate"
              :rules="{ required: true, message: '请选择开始日期', trigger: 'change' }"
            >
              <el-date-picker
                v-model="form.createtimeDate"
                type="datetime"
                placeholder="请选择开始日期"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="截止时间"
              prop="endtimeDate"
              :rules="{ required: true, message: '请选择截止日期', trigger: 'change' }"
            >
              <el-date-picker
                v-model="form.endtimeDate"
                type="datetime"
                placeholder="请选择截止日期"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-else>
          <el-col :span="12">
            <el-form-item
              label="生效时间"
              prop="createtimeStr"
              :rules="{ required: true, message: '请选择开始时间', trigger: 'change' }"
            >
              <el-time-select
                v-model="form.createtimeStr"
                :picker-options="{start: '00:00',step: '00:10',end: '23:50'}"
                placeholder="请选择开始时间"
              ></el-time-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="截止时间"
              prop="endtimeStr"
              :rules="{ required: true, message: '请选择截止时间', trigger: 'change' }"
            >
              <el-time-select
                v-model="form.endtimeStr"
                :picker-options="{start: '00:00',step: '00:10',end: '23:50'}"
                placeholder="请选择截止时间"
              ></el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="显示范围">
          <el-radio v-model="form.display" label="desk">桌面显示</el-radio>
          <el-radio v-model="form.display" label="app">所有APP显示</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogAddNotice=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitAddNotice">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改通知 -->
    <el-dialog
      :visible.sync="dialogEditNotice"
      :close-on-click-modal="false"
      width="40%"
    >
      <span slot="title">编辑通知</span>
      <el-form
        :model="editForm"
        ref="editForm"
        label-width="80px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item label="通知类型:">滚动通知</el-form-item>
        <el-form-item
          label="通知内容"
          prop="message"
          :rules="{ required: true, message: '请输入通知内容', trigger: 'blur' }"
        >
          <el-input v-model="editForm.message" type="textarea" :rows="5"></el-input>
        </el-form-item>
        <el-form-item label="房间号">
          <el-checkbox
            v-model="editForm.tag"
            @change="onChangecheckAll"
            true-label="on"
            false-label="off"
          >全部</el-checkbox>
          <div class="down-tree">
            <el-tag
              v-for="item in roomList"
              :key="item.clientId"
              @click="item.isSelected=!item.isSelected"
              :effect="item.isSelected?'dark':'light'"
              style="margin:5px 10px;padding:0 30px;cursor: pointer;  -webkit-user-select: none;-moz-user-select: none;-o-user-select: none;user-select: none;"
            >{{item.room}}</el-tag>
          </div>
        </el-form-item>
        <el-form-item label="生效方式">
          <el-radio v-model="editForm.effect" label="only">一次性</el-radio>
          <el-radio v-model="editForm.effect" label="every">每天</el-radio>
        </el-form-item>
        <el-row :gutter="20" v-if="editForm.effect=='only'">
          <el-col :span="12">
            <el-form-item
              label="生效时间"
              prop="createtimeDate"
              :rules="{ required: true, message: '请选择开始日期', trigger: 'change' }"
            >
              <el-date-picker
                v-model="editForm.createtimeDate"
                type="datetime"
                placeholder="请选择开始日期"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="截止时间"
              prop="endtimeDate"
              :rules="{ required: true, message: '请选择截止日期', trigger: 'change' }"
            >
              <el-date-picker
                v-model="editForm.endtimeDate"
                type="datetime"
                placeholder="请选择截止日期"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-else>
          <el-col :span="12">
            <el-form-item
              label="生效时间"
              prop="createtimeStr"
              :rules="{ required: true, message: '请选择开始时间', trigger: 'change' }"
            >
              <el-time-select
                v-model="editForm.createtimeStr"
                :picker-options="{start: '00:00',step: '00:10',end: '23:50'}"
                placeholder="请选择开始时间"
              ></el-time-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="截止时间"
              prop="endtimeStr"
              :rules="{ required: true, message: '请选择截止时间', trigger: 'change' }"
            >
              <el-time-select
                v-model="editForm.endtimeStr"
                :picker-options="{start: '00:00',step: '00:10',end: '23:50'}"
                placeholder="请选择截止时间"
              ></el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="显示范围">
          <el-radio v-model="editForm.display" label="desk">桌面显示</el-radio>
          <el-radio v-model="editForm.display" label="app">所有APP显示</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogEditNotice=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitEditNotice">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getTerminalApi,
  getPushApi,
  addPushApi,
  editPushApi,
  delPushApi
} from "../../../api/data";
import Aes from "../../../utils/aes";
import DateFormat from "../../../utils/date";
export default {
  data() {
    return {
      loading:true,
      list: [],
      dialogAddNotice: false, //添加窗口
      form: {
        message: "",
        clientId: "",
        type: "scroll",
        groupId: "",
        createtimeStr: "",
        endtimeStr: "",
        createtimeDate: "",
        endtimeDate: "",
        display: "desk", //显示范围 desk fullScreen所有APP
        effect: "only", //生效方式
        tag: "off" //勾选全部房间按钮
      },
      dialogEditNotice: false, //修改窗口
      editForm: {},
      roomList: [] //房间列表
    };
  },
  computed: {
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    }
  },
  created() {
    this.initLoad();
    this.initLoadTerminal();
  },
  methods: {
    //初始化推送
    initLoad() {
      getPushApi({ groupId: this.placesInfo.groupId, type: "scroll" }).then(
        res => {
          this.loading=false;
          if (res) {
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].effect == "only") {
                res.data[i].isExpire = DateFormat.judgeTime(
                  res.data[i].endtimeDate
                )
                  ? "已过期"
                  : DateFormat.timeDifference(res.data[i].endtimeDate);
              }
            }
            this.list = res.data;
          }
        }
      );
    },
    //初始化终端房间
    initLoadTerminal() {
      getTerminalApi({
        groupId: this.placesInfo.groupId,
        clientType: "all"
      }).then(res => {
        this.roomList = res.data.map(item => {
          return { ...item, isSelected: false };
        });
      });
    },
    //全选房间事件
    onChangecheckAll(val) {
      for (let i = 0; i < this.roomList.length; i++) {
        this.roomList[i].isSelected = val == "on" ? true : false;
      }
    },
    //添加推送
    onSubmitAddNotice() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.roomList.length == 0) {
            this.$message.warning("请选择房间");
            return false;
          }
          this.form.clientId = "";
          //添加当前场所ID进表单
          this.form.groupId = this.placesInfo.groupId;
          //添加已勾选的房间
          for (let i = 0; i < this.roomList.length; i++) {
            if (this.roomList[i].isSelected) {
              if (this.form.clientId == "") {
                this.form.clientId = this.roomList[i].clientId;
              } else {
                this.form.clientId =
                  this.form.clientId + "," + this.roomList[i].clientId;
              }
            }
          }

          addPushApi(this.form).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.initLoad();
              this.dialogAddNotice = false;
              //添加完成后初始化添加数据
              this.form = {
                message: "",
                clientId: "",
                type: "scroll",
                groupId: "",
                createtimeStr: "",
                endtimeStr: "",
                createtimeDate: "",
                endtimeDate: "",
                display: "desk", //显示范围 desk fullScreen所有APP
                effect: "only", //生效方式
                tag: "off" //勾选全部房间按钮
              };
              this.initLoadTerminal();
            }
          });
        } else {
          return false;
        }
      });
    },
    //添加窗口关闭事件
    closeDialogAddNotice() {
      this.$refs["form"].resetFields();
    },
    //打开修改窗口
    openEditNotice(row) {
      this.editForm = { ...row };
      this.dialogEditNotice = true;
      if (this.editForm.effect == "every") {
        this.editForm.createtimeStr = this.subStr(this.editForm.createtimeStr);
        this.editForm.endtimeStr = this.subStr(this.editForm.endtimeStr);
      }

      var array = this.editForm.clientId.split(",");
      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < this.roomList.length; j++) {
          if (array[i] == this.roomList[j].clientId) {
            this.roomList[j].isSelected = true;
          }
        }
      }
      this.editForm.clientId = "";
    },
    //修改推送
    onSubmitEditNotice() {
      this.$refs["editForm"].validate(valid => {
        if (valid) {
          if (this.roomList.length == 0) {
            this.$message.warning("请选择房间");
            return false;
          }
          //添加已勾选的房间
          for (let i = 0; i < this.roomList.length; i++) {
            if (this.roomList[i].isSelected) {
              if (this.editForm.clientId == "") {
                this.editForm.clientId = this.roomList[i].clientId;
              } else {
                this.editForm.clientId =
                  this.editForm.clientId + "," + this.roomList[i].clientId;
              }
            }
          }
          editPushApi(this.editForm).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.initLoad();
              this.dialogEditNotice = false;
              this.initLoadTerminal();
            }
          });
        } else {
          return false;
        }
      });
    },
    //修改推送启用状态
    onChangeSubmitState(row) {
      editPushApi(row).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoad();
        }
      });
    },
    //删除推送通知
    delNotice(row) {
      this.$confirm("此操作将永久删除该通知, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delPushApi({ id: row.id }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      });
    },
    //格式化时间去除秒
    subStr(str) {
      if (str) return str.substring(0, str.lastIndexOf(":"));
    }
  }
};
</script>
<style lang='scss'>
.down-tree {
  height: 200px;
  display: block;
  overflow-y: auto;
  border: 1px solid #d7dae2;
  border-radius: 4px;
}
</style>